/**
 * axios setup to use mock service
 */

import axios from 'axios';

const UNAUTHORIZED = 401;

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS'
    },
    withCredentials: true
});

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
            window.location.href = '/login';
        }

        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

export default axiosServices;
